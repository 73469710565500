import { Communaute, Individu } from "@julienbdx/nous-sommes-common"

export interface IForceInABoxDataNode {
    x?: number
    y?: number
    group: number
    individu: Individu | undefined
    communaute: Communaute | undefined
    groupShaker: string
    visionne: boolean
}

export interface IForceInABoxData {
    nodes: IForceInABoxDataNode[]
    //   links: { source: number; target: number }[]
}

export function ToForceInABox(communautes: Communaute[]): IForceInABoxData {
    const res: IForceInABoxData = {
        nodes: [],
    }
    communautes.forEach((communaute) => {
        communaute.individus.forEach((individu) => {
            res.nodes = [
                ...res.nodes,
                {
                    x: 0,
                    y: 0,
                    group: communaute.id || 0,
                    individu,
                    communaute,
                    visionne: false,
                    groupShaker: "",
                },
            ]
        })
    })

    return res
}
