/* eslint-disable jsx-a11y/no-autofocus,jsx-a11y/media-has-caption */
import React, { useEffect, useState } from "react"
import "../styles/navbar.scss"

import { Communaute, Tag } from "@julienbdx/nous-sommes-common"
import { ReactComponent as Logo } from "../images/nous-sommes.svg"
import { ReactComponent as IconePartage } from "../images/ICONES/PARTAGE.svg"
import { ReactComponent as IconeFermer } from "../images/ICONES/FERMER.svg"
import { ReactComponent as IconeRecherche } from "../images/ICONES/RECHERCHE.svg"
import { ReactComponent as IconeTag } from "../images/ICONES/TAG.svg"
import { ReactComponent as IconeShake } from "../images/ICONES/SHAKE.svg"
import { ReactComponent as IconeAPropos } from "../images/ICONES/APROPOS.svg"
import TagButton from "./TagButton"
import { IForceInABoxDataNode } from "../utils/ToForceInABox"
import CommunauteButton from "./CommunauteButton"
import IndividuButton from "./IndividuButton"
import ModaleAPropos from "./ModaleAPropos"
import useBreakpoint from "../utils/useBreakpoint"
import ModaleTags from "./ModaleTags"
import ModaleCommunaute from "./ModaleCommunaute"
import ModaleShare from "./ModaleShare"

interface NavBarProps {
    nodeSelected: IForceInABoxDataNode | undefined
    nodeHover: IForceInABoxDataNode | undefined
    tagSelected: Tag | undefined
    communautes: Communaute[]
    tags: Tag[]
}

const normalizeString = (str: string) =>
    str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase()

function NavBar({
    nodeSelected,
    nodeHover,
    tagSelected,
    communautes,
    tags,
}: NavBarProps) {
    const [forceVisionne, setForceVisionne] = useState(false)
    const [isShaked, setIsShaked] = useState(false)
    const [image, setImage] = useState("")
    const [nodeAffiche, setNodeAffiche] = useState<IForceInABoxDataNode>()
    const [libelleCommunaute, setLibelleCommunaute] = useState("")
    const [listeTag, setListeTag] = useState<any[] | undefined>([])
    const [mode, setMode] = useState<"init" | "recherche" | "tags">("init")
    const [recherche, setRecherche] = useState("")
    const [modaleAProposOpen, setModaleAProposOpen] = useState(false)
    const [modaleShare, setModaleShare] = useState(false)
    const [modaleTags, setModaleTags] = useState(false)
    const [modaleCommunaute, setModaleCommunaute] = useState(false)
    const breakpoint = useBreakpoint()

    useEffect(() => {
        window.scrollTo(0, 0)
        if (mode === "recherche") {
            window.setTimeout(() => {
                document
                    .getElementById("navbar")
                    ?.scrollIntoView({ block: "start", inline: "nearest" })
            }, 1000)
        }
    }, [mode])

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("set-shaked", {
                detail: isShaked,
            })
        )
    }, [isShaked])

    // Lorsqu'un tag est sélectionné
    document.addEventListener(
        "map-select-tag",
        (() => {
            setNodeAffiche(undefined)
            // eslint-disable-next-line no-undef
        }) as EventListener,
        false
    )

    // Lorsqu'une communauté est sélectionnée
    document.addEventListener(
        "map-select-communaute",
        (() => {
            setIsShaked(false)
            // eslint-disable-next-line no-undef
        }) as EventListener,
        false
    )

    // region Update libelleCommunaute
    useEffect(() => {
        if (tagSelected) {
            setLibelleCommunaute(`#${tagSelected.libelle}`)
            setForceVisionne(false)
        }
    }, [tagSelected])

    useEffect(() => {
        if (nodeHover) {
            setLibelleCommunaute(nodeHover.communaute?.libelle || "")
            setNodeAffiche(nodeHover)
            setListeTag([])
            setMode("init")
            setForceVisionne(false)
        }
    }, [nodeHover])

    useEffect(() => {
        if (nodeAffiche) {
            setLibelleCommunaute(nodeAffiche.communaute?.libelle || "")
        }
    }, [nodeAffiche])
    // endregion

    const toggleModaleCommunaute = () => {
        if (libelleCommunaute.startsWith("#")) {
            return
        }

        const communaute = communautes.find(
            (c) => c.libelle === libelleCommunaute
        )
        if (communaute) {
            document.dispatchEvent(
                new CustomEvent("map-select-communaute", { detail: communaute })
            )
        }
        setModaleCommunaute(!modaleCommunaute)
    }

    const toggleShare = () => {
        setModaleShare(!modaleShare)
    }

    const toggleModeRecherche = () => {
        setRecherche("")
        setMode((val) => {
            if (val === "init") {
                document.querySelector(".input-rechercher")?.scrollIntoView()
                setNodeAffiche(undefined)
            }
            return val === "init" ? "recherche" : "init"
        })
    }

    const toggleModeTag = () => {
        setMode((val) => {
            if (val === "init") {
                setModaleTags(true)
                setListeTag(
                    tags.map((tag) => <TagButton key={tag.id} tag={tag} />)
                )
            }
            return val === "init" ? "tags" : "init"
        })
        setNodeAffiche(undefined)
        setLibelleCommunaute("")
    }

    document.addEventListener(
        "navbar-set-mode-recherche",
        ((e: CustomEvent) => {
            setRecherche("")
            setMode(e.detail ? "recherche" : "init")
            // eslint-disable-next-line no-undef
        }) as EventListener,
        false
    )

    useEffect(() => {
        setImage(
            nodeAffiche?.communaute?.formeCouleur(
                nodeAffiche?.visionne || forceVisionne,
                nodeAffiche?.individu?.variation
            ) || ""
        )
    }, [nodeAffiche, forceVisionne])

    useEffect(() => {
        if (!nodeSelected) {
            setLibelleCommunaute("")
        }
    }, [nodeSelected])

    useEffect(() => {
        setForceVisionne(false)
    }, [nodeSelected])

    useEffect(() => {
        if (mode === "recherche") {
            document.dispatchEvent(
                new CustomEvent("video-affichage", { detail: false })
            )

            if (recherche === "") {
                // Pas de recherche, on affiche les dernières communautés publiées
                setListeTag(() =>
                    communautes
                        .filter((item) => item.individus.length > 0)
                        .sort((a: Communaute, b: Communaute) => {
                            if (a.datePublication === undefined) return 1
                            if (b.datePublication === undefined) return -1

                            return a.datePublication < b.datePublication
                                ? 1
                                : -1
                        })
                        .slice(0, 4)
                        .map((item: Communaute) => (
                            <CommunauteButton
                                key={item.id}
                                communaute={item}
                                setLibelleCommunaute={setLibelleCommunaute}
                            />
                        ))
                )
            } else {
                // On recherche
                setListeTag(() => [
                    communautes
                        .filter(
                            (item) =>
                                normalizeString(item.libelle).indexOf(
                                    normalizeString(recherche)
                                ) !== -1
                        )
                        .map((item: Communaute) => (
                            <CommunauteButton
                                key={`com_${item.id}`}
                                communaute={item}
                                setLibelleCommunaute={setLibelleCommunaute}
                            />
                        )),
                    communautes.map((item) =>
                        item.individus
                            .filter(
                                (ind) =>
                                    normalizeString(ind.prenom || "").indexOf(
                                        normalizeString(recherche)
                                    ) !== -1
                            )
                            .filter((ind) => ind.visible)
                            .map((ind) => (
                                <IndividuButton
                                    key={`ind_${ind.id}`}
                                    communaute={item}
                                    individu={ind}
                                />
                            ))
                    ),
                    tags
                        .filter(
                            (t: Tag) =>
                                normalizeString(t.libelle).indexOf(
                                    normalizeString(recherche)
                                ) !== -1
                        )
                        .map((t) => <TagButton key={`tag_${t.id}`} tag={t} />),
                ])
            }
        } /* else {
            // Pas en cours de recherche, on affiche les tags de l'individu sélectionné
            setListeTag(() =>
                nodeSelected?.individu?.tags.map((tagIndividu) => (
                    <TagButton key={tagIndividu.id} tag={tagIndividu} />
                ))
            )
        } */
    }, [communautes, mode, nodeSelected, recherche, tags])

    const handleShake = () => {
        setMode("init")
        setNodeAffiche(undefined)
        setLibelleCommunaute("")
        setIsShaked((prev) => {
            if (prev) {
                document.dispatchEvent(
                    new CustomEvent("map-groupby-communaute")
                )
            } else {
                document.dispatchEvent(new CustomEvent("map-shake"))
            }

            return !prev
        })
    }

    document.addEventListener(
        "map-individu-visionne",
        (() => {
            setForceVisionne(true)
            // eslint-disable-next-line no-undef
        }) as EventListener,
        false
    )

    document.addEventListener(
        "map-select-tag",
        ((e: CustomEvent) => {
            setListeTag(undefined)
            document.dispatchEvent(new CustomEvent("map-unselect"))
            window.setTimeout(() => {
                setLibelleCommunaute(`#${e.detail.libelle}`)
            }, 500)

            // eslint-disable-next-line no-undef
        }) as EventListener,
        false
    )

    // Lorsqu'un individu est sélectionné
    document.addEventListener(
        "map-select-individu",
        ((e: CustomEvent) => {
            setNodeAffiche({
                group: e.detail?.communaute?.id || 0,
                individu: e.detail?.individu,
                communaute: e.detail?.communaute,
                visionne: true,
                groupShaker: "",
            })
            // eslint-disable-next-line no-undef
        }) as EventListener,
        false
    )

    // Désélectionner individu et tag
    document.addEventListener(
        "map-unselect",
        (() => {
            setNodeAffiche(undefined)
            setLibelleCommunaute("")
            // eslint-disable-next-line no-undef
        }) as EventListener,
        false
    )

    function zoomOnIndividu() {
        document.dispatchEvent(
            new CustomEvent("map-select-individu", {
                detail: {
                    individu: nodeAffiche?.individu,
                    communaute: nodeAffiche?.communaute,
                },
            })
        )

        document.dispatchEvent(
            new CustomEvent("map-individu-visionne", {
                detail: {
                    individu: nodeAffiche?.individu,
                    communaute: nodeAffiche?.communaute,
                },
            })
        )

        document.dispatchEvent(
            new CustomEvent("navbar-set-mode-recherche", { detail: false })
        )
    }

    function getNavbarSmallNoSelection() {
        return (
            <nav role="navigation" className="navbar-mobile">
                <div className="navbar-top">
                    <button
                        tabIndex={0}
                        type="button"
                        className={`btn-transparent ${
                            modaleAProposOpen ? "active" : ""
                        }`}
                        onClick={() => setModaleAProposOpen(!modaleAProposOpen)}
                    >
                        <IconeAPropos />
                    </button>
                </div>
                {mode !== "recherche" && (
                    <div
                        className={`navbar-right ${
                            libelleCommunaute &&
                            (mode === "init" || mode === "tags")
                                ? "details"
                                : ""
                        }`}
                    >
                        <button
                            tabIndex={0}
                            type="button"
                            onClick={() => toggleModeTag()}
                            className="btn-transparent pointer"
                        >
                            <IconeTag />
                        </button>
                        <button
                            tabIndex={0}
                            type="button"
                            onClick={() => handleShake()}
                            className={`btn-transparent pointer ${
                                isShaked ? "active" : ""
                            }`}
                        >
                            <IconeShake />
                        </button>
                    </div>
                )}
                <div className={`navbar-bottom ${mode}`}>
                    <div className="ligne-1">
                        {mode === "recherche" ? (
                            <div className="rechercher">
                                <input
                                    className="input-rechercher"
                                    autoFocus
                                    type="text"
                                    onChange={(e) =>
                                        setRecherche(e.currentTarget.value)
                                    }
                                />
                            </div>
                        ) : (
                            <button
                                tabIndex={0}
                                type="button"
                                onClick={() => {
                                    document.dispatchEvent(
                                        new CustomEvent("video-affichage", {
                                            detail: false,
                                        })
                                    )
                                    setNodeAffiche(undefined)
                                    setListeTag(undefined)
                                    setMode("init")
                                    setIsShaked(false)
                                    document.dispatchEvent(
                                        new CustomEvent(
                                            "map-groupby-communaute"
                                        )
                                    )
                                }}
                                className="btn-transparent pointer"
                                aria-label="Regrouper les individus par communauté"
                            >
                                <Logo />
                            </button>
                        )}
                        <button
                            tabIndex={0}
                            type="button"
                            onClick={() => toggleModeRecherche()}
                            className="btn-transparent pointer btn-rechercher"
                        >
                            <IconeRecherche />
                        </button>
                        {mode === "recherche" && (
                            <div className="propositions">{listeTag}</div>
                        )}
                    </div>
                    {libelleCommunaute && (mode === "init" || mode === "tags") && (
                        <div className="ligne-2">
                            <button
                                className="btn-transparent communaute-libelle"
                                type="button"
                                onClick={() => toggleModaleCommunaute()}
                            >
                                {libelleCommunaute}
                            </button>
                        </div>
                    )}
                </div>
            </nav>
        )
    }

    function getNavbarSmallWithSelection() {
        return (
            <nav role="navigation" className="navbar-mobile-selection">
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                    className="navbar-bottom"
                    onClick={() => {
                        // event.stopPropagation()
                        //   document.dispatchEvent(new CustomEvent("map-unselect"))
                        //   setNodeAffiche(undefined)
                    }}
                >
                    <div className="navbar-bottom-ligne1">
                        <div className="navbar-bottom-ligne1-partie1">
                            <button
                                tabIndex={0}
                                type="button"
                                onClick={() => {
                                    document.dispatchEvent(
                                        new CustomEvent("video-affichage", {
                                            detail: false,
                                        })
                                    )
                                    setNodeAffiche(undefined)
                                    setListeTag(undefined)
                                    setIsShaked(false)
                                    setMode("init")
                                    document.dispatchEvent(
                                        new CustomEvent(
                                            "map-groupby-communaute"
                                        )
                                    )
                                }}
                                className="btn-transparent pointer"
                                aria-label="Regrouper les individus par communauté"
                            >
                                <Logo />
                            </button>
                            <button
                                className="btn-transparent communaute-libelle"
                                type="button"
                                onClick={() => toggleModaleCommunaute()}
                            >
                                {libelleCommunaute}
                            </button>
                        </div>
                        <div className="navbar-bottom-ligne1-partie2">
                            <IconeFermer
                                onClick={() => {
                                    setNodeAffiche(undefined)
                                    document.dispatchEvent(
                                        new CustomEvent("map-unselect")
                                    )
                                }}
                            />
                            <IconePartage onClick={() => toggleShare()} />
                        </div>
                    </div>
                    <div className="navbar-bottom-ligne2">
                        <div className="individu d-flex-center">
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                            <div
                                onClick={() => toggleModaleCommunaute()}
                                className="communaute-forme pointer"
                                // eslint-disable-next-line react/no-danger
                                dangerouslySetInnerHTML={{
                                    __html: image.replaceAll(
                                        "stroke-width:2px",
                                        "stroke-width:3px"
                                    ),
                                }}
                            />
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                            <div
                                className="individu-prenom pointer"
                                onClick={() => zoomOnIndividu()}
                            >
                                {nodeAffiche?.individu?.prenom}
                                {nodeAffiche &&
                                    nodeAffiche.individu &&
                                    !nodeAffiche?.individu?.visible && (
                                        <span className="bientot">
                                            <br />
                                            Bientôt en&nbsp;ligne
                                        </span>
                                    )}
                            </div>
                        </div>
                        {!nodeAffiche && <div className="tags">{listeTag}</div>}
                        {nodeAffiche &&
                            nodeAffiche.individu &&
                            nodeAffiche.individu.variation && (
                                <div className="variation">
                                    {nodeAffiche.individu.variationLibelle}
                                </div>
                            )}
                    </div>
                </div>
            </nav>
        )
    }

    function getLargeLigne1Partie1() {
        return (
            <div className="partie-1">
                <div className="logo">
                    <button
                        tabIndex={0}
                        type="button"
                        onClick={() => {
                            document.dispatchEvent(
                                new CustomEvent("video-affichage", {
                                    detail: false,
                                })
                            )
                            setNodeAffiche(undefined)
                            setIsShaked(false)
                            setListeTag(undefined)
                            setMode("init")
                            document.dispatchEvent(
                                new CustomEvent("map-groupby-communaute")
                            )
                        }}
                        className="btn-transparent pointer"
                        aria-label="Regrouper les individus par communauté"
                    >
                        <Logo />
                    </button>
                </div>
                {mode === "recherche" ? (
                    <div className="rechercher">
                        <input
                            className="input-rechercher"
                            autoFocus
                            type="text"
                            onChange={(e) =>
                                setRecherche(e.currentTarget.value)
                            }
                        />
                    </div>
                ) : (
                    <>
                        <button
                            aria-hidden={libelleCommunaute?.length === 0}
                            className="btn-transparent communaute-libelle"
                            type="button"
                            onClick={() => toggleModaleCommunaute()}
                            tabIndex={libelleCommunaute.length === 0 ? -1 : 0}
                        >
                            {libelleCommunaute}
                        </button>
                        <div className="individu">
                            <div className="d-flex-center">
                                <button
                                    aria-hidden
                                    tabIndex={-1}
                                    type="button"
                                    onClick={() => toggleModaleCommunaute()}
                                    className="btn-transparent communaute-forme pointer"
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: image.replaceAll(
                                            "stroke-width:2px",
                                            "stroke-width:4px"
                                        ),
                                    }}
                                />
                                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                                <button
                                    type="button"
                                    tabIndex={
                                        nodeAffiche && nodeAffiche.individu
                                            ? 0
                                            : -1
                                    }
                                    className="btn-transparent individu-prenom pointer"
                                    onClick={() => zoomOnIndividu()}
                                >
                                    {nodeAffiche?.individu?.prenom}
                                    {nodeAffiche &&
                                        nodeAffiche.individu &&
                                        !nodeAffiche?.individu?.visible && (
                                            <span className="bientot">
                                                <br />
                                                Bientôt en&nbsp;ligne
                                            </span>
                                        )}
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }

    function getLargeLigne1Partie2() {
        if (
            nodeSelected &&
            nodeSelected.individu &&
            nodeSelected.individu.id === nodeAffiche?.individu?.id
        ) {
            return (
                <div className="partie-2 partage">
                    <div className="commandes">
                        <IconePartage onClick={() => toggleShare()} />
                    </div>
                </div>
            )
        }

        return (
            <div className="partie-2">
                <div className="commandes">
                    <button
                        tabIndex={0}
                        type="button"
                        aria-label="Rechercher un individu, une communauté ou un tag"
                        className="btn-transparent"
                    >
                        <IconeRecherche onClick={toggleModeRecherche} />
                    </button>
                    <button
                        type="button"
                        aria-label="Mélanger les individus"
                        className={`btn-transparent pointer ${
                            isShaked ? "active" : ""
                        }`}
                    >
                        <IconeShake onClick={handleShake} />
                    </button>
                    <button
                        type="button"
                        aria-label="Afficher tous les tags"
                        className="btn-transparent"
                    >
                        <IconeTag onClick={() => toggleModeTag()} />
                    </button>
                </div>
            </div>
        )
    }

    function getLargeLigne1() {
        return (
            <div className="ligne-1">
                {getLargeLigne1Partie1()}
                {getLargeLigne1Partie2()}
            </div>
        )
    }

    function getLargeLigne2() {
        if (nodeAffiche?.individu?.variation) {
            return (
                <div className="ligne-2">
                    <div className="variation" style={{ marginTop: 0 }}>
                        {nodeAffiche.individu.variationLibelle}
                    </div>
                </div>
            )
        }

        if (
            (mode === "init" || mode === "tags") &&
            !(nodeAffiche?.individu && (listeTag?.length || []) > 0)
        ) {
            return null
        }

        return (
            <div className="ligne-2">
                {listeTag && mode !== "tags" && (listeTag?.length || 0) > 0
                    ? listeTag
                    : null}
                {nodeAffiche &&
                    nodeAffiche.individu &&
                    nodeAffiche.individu.variation && (
                        <div className="variation">
                            {nodeAffiche.individu.variationLibelle}
                        </div>
                    )}
            </div>
        )
    }

    function getNavbarLarge() {
        return (
            <nav role="navigation" className="navbar">
                <div
                    className={`navbar-left ${
                        mode === "recherche" ||
                        (nodeSelected?.individu && (listeTag?.length || []) > 0)
                            ? "details"
                            : ""
                    }`}
                >
                    {getLargeLigne1()}
                    {getLargeLigne2()}
                </div>
                <button
                    type="button"
                    className={`navbar-right ${
                        modaleAProposOpen ? "active" : ""
                    }`}
                    onClick={() => setModaleAProposOpen(!modaleAProposOpen)}
                >
                    <IconeAPropos />
                </button>
            </nav>
        )
    }

    function getNavbar() {
        // Small
        if (breakpoint === "xs") {
            if (!nodeSelected || !nodeSelected.individu) {
                return getNavbarSmallNoSelection()
            }

            return getNavbarSmallWithSelection()
        }

        // Large
        return getNavbarLarge()
    }

    return (
        <>
            <ModaleAPropos
                communautes={communautes}
                open={modaleAProposOpen}
                setOpen={setModaleAProposOpen}
            />
            <ModaleShare
                open={modaleShare}
                setOpen={setModaleShare}
                node={nodeAffiche}
            />
            <ModaleTags open={modaleTags} setOpen={setModaleTags} tags={tags} />
            <ModaleCommunaute
                open={modaleCommunaute}
                setOpen={setModaleCommunaute}
                communaute={nodeAffiche?.communaute}
            />
            <div
                id="navbar"
                tabIndex={
                    modaleAProposOpen || modaleShare || modaleCommunaute
                        ? -1
                        : undefined
                }
            >
                {getNavbar()}
            </div>
        </>
    )
}

export default NavBar
