// eslint-disable-next-line import/prefer-default-export
import React from "react"

export function isTouchDevice() {
    return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        // @ts-ignore
        navigator.msMaxTouchPoints > 0
    )
}

export function removeAllClass(className: string) {
    const elements = document.querySelectorAll(`.${className}`)
    elements.forEach((element) => {
        element.classList.remove(className)
    })
}

export function isSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
}

export const iconClose = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32"
        height="32"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
    >
        <line x1="18" y1="6" x2="6" y2="18" />
        <line x1="6" y1="6" x2="18" y2="18" />
    </svg>
)
