import React from "react"
import { fetchApi, Tag } from "@julienbdx/nous-sommes-common"
import { getClientId } from "../utils/Cookie"
import { DEV, getEndpoint } from "./NousSommes"

interface TagButtonProps {
    tag: Tag
}

function TagButton({ tag }: TagButtonProps) {
    function traceTagConsultation() {
        if (!DEV) {
            fetchApi(getEndpoint(), "/api/consultations", {
                method: "POST",
                body: JSON.stringify({
                    tag: tag?.["@id"],
                    source: window.location.origin,
                    ident: getClientId(),
                    width: window.innerWidth,
                    height: window.innerHeight,
                    client: JSON.stringify({
                        navigator: {
                            userAgent: navigator.userAgent,
                            platform: navigator.platform,
                            language: navigator.language,
                            vendor: navigator.vendor,
                        },
                    }),
                }),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    const handleTagSelected = () => {
        traceTagConsultation()
        document.dispatchEvent(
            new CustomEvent("map-select-tag", { detail: tag })
        )
    }

    return (
        <button
            type="button"
            onClick={handleTagSelected}
            className="tag"
            aria-label={`Tag : ${tag.libelle}`}
        >
            #{tag.libelle}
        </button>
    )
}

export default TagButton
