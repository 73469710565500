import React from "react"
import { Communaute } from "@julienbdx/nous-sommes-common"

interface CommunauteButtonProps {
    setLibelleCommunaute: any
    communaute: Communaute
}

function CommunauteButton({
    communaute,
    setLibelleCommunaute = undefined,
}: CommunauteButtonProps) {
    const handleCommunauteSelected = () => {
        //   setSelectedTag(tag)
        setLibelleCommunaute?.(communaute.libelle)
        document.dispatchEvent(
            new CustomEvent("map-select-communaute", {
                detail: communaute,
            })
        )

        document.dispatchEvent(
            new CustomEvent("navbar-set-mode-recherche", { detail: false })
        )
    }

    return (
        <button
            type="button"
            onClick={handleCommunauteSelected}
            className="tag"
            aria-label={`Communauté : ${communaute.libelle}`}
        >
            {communaute.libelle}
        </button>
    )
}

export default CommunauteButton
