import React from "react"
import { Communaute, Individu } from "@julienbdx/nous-sommes-common"

interface IndividuButtonProps {
    individu: Individu
    communaute: Communaute
}

function IndividuButton({ communaute, individu }: IndividuButtonProps) {
    const handleIndividuSelected = () => {
        document.dispatchEvent(
            new CustomEvent("map-select-individu", {
                detail: { individu, communaute },
            })
        )

        document.dispatchEvent(
            new CustomEvent("map-individu-visionne", {
                detail: { individu, communaute },
            })
        )

        document.dispatchEvent(
            new CustomEvent("navbar-set-mode-recherche", { detail: false })
        )
    }

    return (
        <button
            type="button"
            onClick={handleIndividuSelected}
            className="tag"
            aria-label={`Individu : ${individu.prenom} de la communauté ${individu.communaute?.libelle}`}
        >
            {individu.prenom}
            <span className="communaute">
                {" "}
                de &laquo;&nbsp;{communaute.libelle}&nbsp;&raquo;
            </span>
        </button>
    )
}

export default IndividuButton
