import React, { Dispatch } from "react"
import { Tag } from "@julienbdx/nous-sommes-common"
import { ReactComponent as Logo } from "../images/nous-sommes.svg"
import Modale from "./Modale"
import "../styles/modaletags.scss"
import TagButton from "./TagButton"

interface ModaleTagsProps {
    open: boolean
    setOpen: Dispatch<boolean>
    tags: Tag[]
}

function ModaleTags({ open, setOpen, tags }: ModaleTagsProps) {
    return (
        <Modale
            open={open}
            setOpen={setOpen}
            className="modale-tags"
            content={
                <>
                    <div className="logo-outer">
                        <Logo />
                    </div>
                    <div className="content-modale-tags">
                        <h1 className="visually-hidden">Tags</h1>
                        {tags
                            .sort((t1, t2) =>
                                t1.libelle.localeCompare(t2.libelle)
                            )
                            .map((t) => (
                                <div
                                    key={t.id}
                                    style={{ display: "inline-block" }}
                                >
                                    <TagButton key={t.id} tag={t} />
                                </div>
                            ))}
                    </div>
                </>
            }
        />
    )
}

export default ModaleTags
