import React, { Dispatch, useEffect, useRef } from "react"
import "../styles/apropos.scss"
import "../styles/modalecommunaute.scss"
import { Communaute } from "@julienbdx/nous-sommes-common"
import { ReactComponent as Logo } from "../images/nous-sommes.svg"
import Modale from "./Modale"
import "../styles/modale-apropos.scss"
import { supprimerTousIndividusVisionnes } from "../utils/Cookie"

interface AProposProps {
    open: boolean
    setOpen: Dispatch<boolean>
    communautes: Communaute[]
}

function ModaleAPropos({ open, setOpen, communautes }: AProposProps) {
    const refModale = useRef<HTMLDivElement>(null)
    const [showingEmail, setShowingEmail] = React.useState(false)

    useEffect(() => {
        if (open) {
            refModale.current?.focus()
        }
    }, [open])

    const CONTACT_EMAIL = "contact@petitessecousses.fr"

    const email = (mail: string) =>
        showingEmail ? (
            <a href={`mailto:${mail}`}>{mail}</a>
        ) : (
            <button
                onClick={(event) => {
                    event.stopPropagation()
                    setShowingEmail(true)
                }}
                type="button"
                style={{ cursor: "pointer", marginTop: 10 }}
            >
                Afficher le mail de contact
            </button>
        )

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
        <Modale
            open={open}
            setOpen={setOpen}
            className="apropos"
            content={
                <div ref={refModale}>
                    <h1 className="visually-hidden">
                        Présentation de Nous Sommes
                    </h1>
                    <div className="logo-outer">
                        <Logo />
                    </div>
                    <div className="content-apropos">
                        <p>
                            <span className="visually-hidden">Nous sommes</span>
                            ... des individus issus de la même espèce.
                        </p>
                        <p>
                            A une époque où la tendance est au repli sur soi, le
                            projet &ldquo;Nous Sommes…&ldquo; utilise
                            l&apos;outil vidéo pour désacraliser le rapport à
                            autrui.
                        </p>
                        <p>
                            Des vidéos touchantes pour démystifier l’autre. Le
                            reconnecter à soi. Pour faire groupe. Parce que si
                            nous interviewons de petites communautés - et ce mot
                            de communauté est beau bien qu’il soit aujourd’hui
                            dévoyé - nous appartenons à une communauté plus
                            grande encore : l’espèce humaine. Et malgré tout ce
                            qui nous sépare, il reste entre chacun de nous des
                            points de contact : ce que nous ressentons. Notre
                            capacité à nous émouvoir.
                        </p>
                        <p>
                            Par un jeu de questions sur les sujets du sensible
                            (Quel est ton plus lointain souvenir ? Où trouves-tu
                            du réconfort ? Quel serait ton rêve le plus fou ? …)
                            l’idée est de révéler l&apos;humain pour aider à
                            dépasser les préjugés.
                        </p>
                        <p>
                            Un projet tout doux, aussi généreux et pudique que
                            chacun de nous peut en être capable.
                            <br />
                            Une ode à l&apos;altérité.
                        </p>
                        <h2>Qui sommes-nous ?</h2>
                        <p>
                            Créée en 2010, à l’initiative de Jérôme Batteux
                            (auteur et metteur en scène), la Cie des Petites
                            Secousses œuvre dans le champ du spectacle vivant.
                        </p>
                        <p>
                            Que les enjeux des spectacles soient sociaux,
                            intimes, poétiques ou philosophiques, l&apos;envie
                            est toujours de mettre en &oelig;uvre des modes de
                            narration qui offrent autant de points
                            d&apos;accroche fixes que de lignes floues. Une
                            façon de tendre la main au spectateur pour
                            l&apos;emmener dans des lieux où son imaginaire et
                            sa propre histoire peuvent s&apos;exprimer. Nous
                            avons le sentiment d&apos;y avoir réussi quand les
                            personnes qui ont assisté à nos spectacles disent
                            que nous leur avons parlé d&apos;eux.
                        </p>
                        <p>
                            Le théâtre est notre acte politique et citoyen. Pas
                            forcément pour percuter (nos perceptions du monde ne
                            nous semblent pas forcément plus valables que celles
                            des autres), nous créons pour secouer doucement en
                            espérant provoquer la discussion, pour échanger.
                        </p>
                        <h2>Contact</h2>
                        <div className="realisation">
                            <div
                                className="realisation-item"
                                style={{ width: "100%" }}
                            >
                                <h3>Attachée de production</h3>
                                <p>
                                    <b>Estelle Martinet</b>
                                    <br />
                                    {email("martinetestelle@yahoo.fr")}
                                </p>
                            </div>
                        </div>
                        <h2>Les communautés</h2>
                        <ul>
                            {communautes
                                .sort(
                                    (c1, c2) =>
                                        -1 * c1.slug.localeCompare(c2.slug)
                                )
                                .map((c) => (
                                    <li key={c.id}>
                                        <div
                                            key={c.id}
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            <div className="communaute-description">
                                                <div className="communaute-forme">
                                                    <div
                                                        // eslint-disable-next-line react/no-danger
                                                        dangerouslySetInnerHTML={{
                                                            __html: c.formeCouleur(
                                                                true
                                                            ),
                                                        }}
                                                    />
                                                    {c.individus.some(
                                                        (i) => i.variation
                                                    ) && (
                                                        <div
                                                            // eslint-disable-next-line react/no-danger
                                                            dangerouslySetInnerHTML={{
                                                                __html: c.formeCouleur(
                                                                    true,
                                                                    true
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                                <div className="communaute-content">
                                                    <h3 className="communaute-libelle">
                                                        {c.libelle}
                                                    </h3>
                                                    {c.presentation && (
                                                        <>
                                                            <h4>
                                                                Présentation
                                                            </h4>
                                                            <div
                                                                className="paragraph"
                                                                // eslint-disable-next-line react/no-danger
                                                                dangerouslySetInnerHTML={{
                                                                    __html: c.presentation,
                                                                }}
                                                            />
                                                        </>
                                                    )}
                                                    {c.lien && (
                                                        <>
                                                            <h4>
                                                                Plus
                                                                d&apos;informations
                                                            </h4>
                                                            <p>
                                                                <a
                                                                    href={
                                                                        c.lien
                                                                    }
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    Site
                                                                    internet
                                                                </a>
                                                            </p>
                                                        </>
                                                    )}
                                                    {c.partenaires && (
                                                        <>
                                                            <h4>
                                                                Partenaire(s)
                                                            </h4>
                                                            <div className="paragraph partenaires">
                                                                <div
                                                                    // eslint-disable-next-line react/no-danger
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: c.partenaires,
                                                                    }}
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                        </ul>
                        <h2>Réalisation</h2>
                        <div className="realisation">
                            <div className="realisation-item">
                                <h3>Graphisme</h3>
                                <p>
                                    <b>mmm collectif</b>
                                    <br />
                                    <a
                                        href="https://mmmcollectif.com"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        mmmcollectif.com
                                    </a>
                                    <br />
                                    {email("mmmcollectif@gmail.com")}
                                </p>
                            </div>
                            <div className="realisation-item">
                                <h3>Vidéos</h3>
                                <p>
                                    <b>Aurore Aulong</b>
                                    <br />
                                    {email("aaulong@gmail.com")}
                                </p>
                            </div>
                            <div className="realisation-item">
                                <h3>Habillage sonore</h3>
                                <p>
                                    <b>David Chiesa</b>
                                    <br />
                                    {email("david@unensemble.net")}
                                </p>
                            </div>
                            <div className="realisation-item">
                                <h3>Développement</h3>
                                <p>
                                    <b>Julien Lemonnier</b>
                                    <br />
                                    {email("julien@lmnr.fr")}
                                </p>
                            </div>
                            <div className="realisation-item">
                                <h3>Interviews</h3>
                                <p>
                                    <b>Jérôme Batteux</b>
                                    <br />
                                    {email(
                                        "jerome.batteux@petitessecousses.fr"
                                    )}
                                </p>
                            </div>
                            <div className="realisation-item">&nbsp;</div>
                        </div>
                        <h2>Mentions légales</h2>
                        <h3>Identification du propriétaire</h3>
                        <p>
                            Compagnie des Petites Secousses
                            <br />
                            Au Dôme
                            <br />
                            221 avenue de Thouars
                            <br />
                            33400 Talence
                            <br />
                            {email(CONTACT_EMAIL)}
                        </p>
                        <p>
                            <b>SIRET : </b>529 602 476 00010 - Enregistré le{" "}
                            17/11/2010
                            <br />
                            <b> Numéro TVA Intracommunautaire&nbsp;: </b>
                            FR14529602476
                        </p>
                        <h3>Hébergement</h3>
                        <p>
                            OVH SAS, filiale de la société OVH Groupe SA.
                            Données hébergées sur des serveurs en France.
                            <br />
                            <br />
                            Société immatriculée au RCS de Lille sous le numéro
                            537 407 926
                            <br />
                            sise 2, rue Kellermann, 59100 Roubaix, FRANCE
                        </p>
                        <h3>Politique de gestion des cookies</h3>
                        <p>
                            Le site nous-sommes.fr n&apos;utilise aucun cookie.
                            La liste des individus visionnées est stockée dans
                            le navigateur de l&apos;utilisateur via le local
                            storage et n&apos;est partagé avec aucun tiers.
                        </p>
                        <h3>Politique de suivi des connexions</h3>
                        <p>
                            Le site est accessible sans authentification et ne
                            collecte aucune donnée personnelle. Seul un traçage{" "}
                            <b>anonyme</b> des consultations des vidéos est
                            réalisé à des fins d&apos;amélioration du site. Les
                            données recueillies à cet effet ne concernent que le
                            navigateur et la résolution de l&apos;écran du
                            dispositif utilisé. Vous pouvez obtenir plus
                            d&apos;informations à ce sujet en utilisant le mail
                            de contact suivant : {email(CONTACT_EMAIL)}
                        </p>
                        <h3>
                            Politique d&apos;accès, de rectification et de
                            suppression des données
                        </h3>
                        <p>
                            En tant que participant au projet Nous Sommes, vous
                            pouvez à tout moment demander l&apos;accès à
                            l&apos;ensemble des données qui vous concernent,
                            demander leur rectification et/ou leur suppression.
                            Vous devez pour cela nous contacter en utilisant
                            l&apos;adresse suivante : {email(CONTACT_EMAIL)}
                        </p>
                        <h3>Propriété intellectuelle</h3>
                        <p>
                            Les contenus du site sont la propriété de la Cie des
                            Petites Secousses. Toute reproduction,
                            représentation, modification, publication,
                            adaptation de tout ou partie des contenus, quel que
                            soit le moyen ou le procédé utilisé, est interdite,
                            sauf autorisation écrite préalable de la Cie des
                            Petites Secousses.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="pointer"
                            type="button"
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                supprimerTousIndividusVisionnes()
                                window.location.assign(window.origin)
                            }}
                        >
                            Vider mon historique de navigation
                        </button>
                    </div>
                </div>
            }
        />
    )
}

export default ModaleAPropos
