/* eslint-disable */
import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import "../styles/video.scss";
import { Communaute, Individu } from "@julienbdx/nous-sommes-common";
import { removeAllClass } from "../utils/misc";
import { log } from "util";
import TagButton from "./TagButton";
import { ReactComponent as IconeSrtOn } from "../images/ICONES/SRT.svg"

interface VideoPlayerProps {
  individu: Individu;
  communaute: Communaute;
}

// eslint-disable-next-line no-unused-vars
function VideoPlayer({ individu, communaute }: VideoPlayerProps) {
  const [isSlow, setIsSlow] = useState<boolean | undefined>(
    window.sessionStorage.getItem("isSlow") === null ? undefined : window.sessionStorage.getItem("isSlow") === "true");
  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(100);
  const DEV = window.location.origin === "http://localhost:3000" || window.location.origin === "http://localhost:8080";
  const BETA = window.location.origin === "https://beta.nous-sommes.fr";

  const refVideo = useRef<HTMLVideoElement>(null);
  const [prevFocus, setPrevFocus] = useState<HTMLElement>();
  const [subtitled, setSubtitled] = useState<boolean>(
    localStorage.getItem("subtitled") ? localStorage.getItem("subtitled") === "true" : true
  );

  const handleExit = () => {
    document.dispatchEvent(
      new CustomEvent("video-affichage", { detail: false })
    );

    window.setTimeout(() => {
      document.getElementById("map-container")?.classList.remove("with-selection");
      removeAllClass("selected");
    }, 0);

    //if (prevFocus) prevFocus.focus();
  };

  const toggleSubtitles = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setSubtitled(!subtitled);
  };

  useEffect(() => {
    setPrevFocus(document.activeElement as HTMLElement);
    refVideo.current?.focus();
  }, [individu]);

  function proceedSubtitles() {
    if (refVideo.current) {
      for (let i = 0; i < refVideo.current.textTracks.length; i++) {
        if (refVideo.current.textTracks[i].language == "fr") {
          refVideo.current.textTracks[i].mode = subtitled ? "showing" : "hidden";
        }
      }
      localStorage.setItem("subtitled", subtitled ? "true" : "false");
    }
  }

  useEffect(() => {
    proceedSubtitles();
  }, [subtitled]);

  useEffect(() => {
    const w = window.innerWidth;
    const h = window.innerHeight;

    if (w < h) {
      setWidth(w - 30 - 20);
      setHeight(9 * (w - 30 - 20) / 16);
    } else {
      setHeight(h - 220 - 20);
      setWidth((h - 220 - 20) * 16 / 9);
    }
  }, [individu]);

  if (!individu || (!individu.visible && !BETA)) return null;

  const imageAddr = "https://videos.nous-sommes.fr/test.jpg";
  const downloadSize = 427572; // this must match with the image above
  let download: HTMLImageElement | null;

  let startTime, endTime;

  async function measureConnectionSpeed() {
    startTime = (new Date()).getTime();
    const cacheBuster = "?nnn=" + startTime;

    download = new Image();
    download.src = imageAddr + cacheBuster;

    const timeoutIsSlow = window.setTimeout(() => {
      if (isSlow === undefined) {
        setIsSlow(true);
        window.sessionStorage.setItem("isSlow", "true");
      }
    }, 3500);

    // this returns when the image is finished downloading
    await download.decode();
    window.clearTimeout(timeoutIsSlow);

    endTime = (new Date()).getTime();
    const duration = (endTime - startTime) / 1000;
    const bitsLoaded = downloadSize * 8;
    const speedBps = (bitsLoaded / duration);
    const speedKbps = ((speedBps) / 1024);
    return speedKbps;
  }

  useEffect(() => {
    if (DEV) {
      setIsSlow(false);
    } else if (isSlow === undefined) {
      measureConnectionSpeed().then((value) => {
        setIsSlow(value < 1500);
        window.sessionStorage.setItem("isSlow", value < 1500 ? "true" : "false");
      });
    }
  }, []);

  if (isSlow === undefined) {
    return <></>;
  }

  function getVideoUrl() {
    if (DEV) {
      return "/videos/"
        + communaute.slug + "/"
        + individu.slug
        + (isSlow ? "-sd" : "")
        + ".mp4";
    } else {
      return "https://videos.nous-sommes.fr/video.php?video=/"
        + communaute.slug + "/"
        + individu.slug
        + (isSlow ? "-sd" : "")
        + ".mp4";
    }
  }

  return (
    <div id="video-player" onClick={handleExit}>
      <video className="video" ref={refVideo} controls controlsList="nodownload" autoPlay
             poster={"/images/COMMUNAUTES/" + communaute?.slug + "-play.jpg"}
             style={{ width: width, height: height }}
             onPlay={() => proceedSubtitles()}
             onKeyUp={(event) => {
               if (event.code === "Escape") {
                 handleExit();
               }
             }}
             onClick={(e) => {
               e.stopPropagation();
             }}>
        <source src={getVideoUrl()} />
        {(individu.srt || BETA) && <track kind="subtitles"
                                          src={"/srt/" + communaute.slug + "/" + individu.slug + ".vtt"} srcLang="fr"
                                          label="Français" />}
      </video>
      <div className={"sub-video"} style={{ width: width }}>
        <div className={"tag-container"}>
          {individu?.tags.map((tag) => (
            <TagButton key={tag.id} tag={tag} />
          ))}
        </div>
        <div style={{ marginLeft:20, display: (individu.srt || BETA) ? "block" : "none" }}>
          <button title={subtitled ? "Désactiver les sous-titres" : "Activer les"
            + " sous-titres"} className={"pointer srt " + (subtitled ? "active" : "")} onClick={toggleSubtitles}>
            {width < 420 ? "ST" : "Sous-titres"}
          </button>
        </div>
      </div>
    </div>
  );

}

export default VideoPlayer;
