import React, {
    Dispatch,
    ReactElement,
    useEffect,
    useRef,
    useState,
} from "react"
import "../styles/modale.scss"
import { iconClose } from "../utils/misc"

interface AModale {
    open: boolean
    setOpen: Dispatch<boolean>
    content: ReactElement
    // eslint-disable-next-line react/require-default-props
    className?: string | undefined
}

function Modale({ open, setOpen, content, className = "" }: AModale) {
    // if (!open) return null
    const refContent = useRef<HTMLDivElement>(null)
    const refModale = useRef<HTMLDivElement>(null)
    const [prevFocus, setPrevFocus] = useState<HTMLElement>()

    /*    const findFirstFocusableElement = (container: HTMLElement) =>
        Array.from(container.getElementsByTagName("*")).find(isFocusable)

    const isFocusable = (item: Element): HTMLElement | boolean => {
        if ((item as HTMLElement).tabIndex < 0) {
            return false
        }
        switch (item.tagName) {
            case "A":
                return !!(item as HTMLLinkElement).href
            case "INPUT":
                return (
                    (item as HTMLInputElement).type !== "hidden" &&
                    !(item as HTMLInputElement).disabled
                )
            case "SELECT":
            case "TEXTAREA":
            case "BUTTON":
                return !(item as HTMLButtonElement).disabled
            default:
                return false
        }
    } */

    useEffect(() => {
        document.dispatchEvent(
            new CustomEvent("map-set-focus", { detail: !open })
        )

        if (open) {
            setPrevFocus(document.activeElement as HTMLElement)
            refModale.current?.removeAttribute("inert")

            if (open && refContent.current) {
                // const elem = findFirstFocusableElement(refContent.current)
                // if (elem) {
                //     ;(elem as HTMLElement).focus()
                // } else {
                refContent.current.focus()
                // }
            }
        } else {
            refModale.current?.setAttribute("inert", "")
            if (prevFocus) prevFocus.focus()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/interactive-supports-focus
        <div
            ref={refModale}
            aria-hidden={!open}
            role="button"
            className={`modale-back ${open ? "open" : ""} ${className}-back`}
            onClick={() => {
                setOpen(false)
            }}
        >
            <div
                className={`modale ${open ? "open" : ""}  ${className}`}
                aria-modal="true"
            >
                <div ref={refContent} className="content">
                    {open && content}
                </div>
                <button
                    tabIndex={!open ? -1 : undefined}
                    className="button-close"
                    type="button"
                    onClick={() => {
                        setOpen(false)
                    }}
                    aria-label="Fermer la fenêtre"
                >
                    {iconClose}
                </button>
            </div>
        </div>
    )
}

export default Modale
