import React, { Dispatch, useEffect, useState } from "react"
import {
    EmailIcon,
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share"
import Modale from "./Modale"
import { IForceInABoxDataNode } from "../utils/ToForceInABox"
import { toHashtag } from "../utils/string"
import "../styles/modaleshare.scss"

interface ModaleShareProps {
    open: boolean
    setOpen: Dispatch<boolean>
    node: IForceInABoxDataNode | undefined
}

function ModaleShare({ open, setOpen, node }: ModaleShareProps) {
    const [url, setUrl] = useState("")

    useEffect(() => {
        setUrl(
            `${window.location.origin}/${node?.communaute?.slug}/${node?.individu?.slug}`
        )
    }, [node])

    if (!node || !node.communaute || !node.individu) return null
    return (
        <Modale
            open={open}
            setOpen={setOpen}
            className="modale-share"
            content={
                <>
                    <h1 className="text-center" style={{ marginBottom: 30 }}>
                        Partager la page de
                    </h1>
                    <h2 className="text-center">
                        {node.individu?.prenom} de &laquo;{" "}
                        {node.communaute?.libelle} &raquo;
                    </h2>
                    <div className="liens d-flex-center">
                        <a
                            className="d-flex-center"
                            target="_blank"
                            style={{
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                textDecoration: "none",
                                color: "#000",
                            }}
                            href={`mailto:?subject=${encodeURI(
                                `Nous sommes : ${node.individu.prenom} @${node.communaute.libelle}`
                            )}&body=${encodeURI(
                                "Consultez la vidéo en cliquant sur le lien suivant : "
                            )}${encodeURI(url)}`}
                            rel="noreferrer"
                        >
                            <EmailIcon round />
                            <span className="social-media-name">Email</span>
                        </a>
                        <FacebookShareButton
                            url={url}
                            quote={`Nous sommes : découvrez ${node.individu.prenom} @${node.communaute.libelle}`}
                            hashtag="#noussommes"
                        >
                            <FacebookIcon round />
                            <br />
                            <span className="social-media-name">Facebook</span>
                        </FacebookShareButton>
                        <TwitterShareButton
                            url={url}
                            title={`Nous sommes : découvrez ${node.individu.prenom} de "${node.communaute.libelle}"`}
                            hashtags={[
                                "NousSommes",
                                toHashtag(node.individu.prenom as string),
                                toHashtag(node.communaute.libelle),
                            ]}
                        >
                            <TwitterIcon round />
                            <br />
                            <span className="social-media-name">Twitter</span>
                        </TwitterShareButton>
                        <WhatsappShareButton
                            url={url}
                            title={`Nous sommes : découvrez ${node.individu.prenom} de "${node.communaute.libelle}"`}
                        >
                            <WhatsappIcon round />
                            <br />
                            <span className="social-media-name">WhatsApp</span>
                        </WhatsappShareButton>
                        <TelegramShareButton
                            url={url}
                            title={`Nous sommes : découvrez ${node.individu.prenom} de "${node.communaute.libelle}"`}
                        >
                            <TelegramIcon round />
                            <br />
                            <span className="social-media-name">Telegram</span>
                        </TelegramShareButton>
                        <LinkedinShareButton
                            url={url}
                            title="Nous sommes"
                            source="https://nous-sommes.fr"
                            summary={`Nous sommes : découvrez ${node.individu.prenom} de "${node.communaute.libelle}"`}
                        >
                            <LinkedinIcon round />
                            <br />
                            <span className="social-media-name">LinkedIn</span>
                        </LinkedinShareButton>
                    </div>
                </>
            }
        />
    )
}

export default ModaleShare
