import { useEffect, useState } from "react"

export const breakpoints = {
    0: "xs",
    576: "sm",
    768: "md",
    992: "lg",
    1200: "xl",
    1400: "xxl",
}

const useBreakpoint = () => {
    const [breakpoint, setBreakPoint] = useState("")
    const [windowSize, setWindowSize] = useState<{
        width: number | undefined
        height: number | undefined
    }>({
        width: undefined,
        height: undefined,
    })

    const handleResize = () => {
        setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
        })
    }

    useEffect(() => {
        window.addEventListener("resize", handleResize)
        handleResize()

        const width = windowSize.width || 0
        const breakpointWidth = Object.keys(breakpoints)
            .map((k) => parseInt(k, 10))
            .filter((b) => width >= b)
            .reverse()[0]

        if (breakpointWidth !== undefined) {
            // @ts-ignore
            setBreakPoint(breakpoints[breakpointWidth])
        }

        return () => window.removeEventListener("resize", handleResize)
    }, [windowSize.width])

    return breakpoint
}

export default useBreakpoint
