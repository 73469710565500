import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import NousSommes from "./components/NousSommes"

export default function Router() {
    const [params, setParams] = useState<string[]>([])
    const location = useLocation()
    useEffect(() => {
        setParams(location?.pathname.split("/").filter((c) => c !== "") || [])
    }, [location])

    return (
        <NousSommes
            communauteSlug={params[0] || undefined}
            individuSlug={params[1] || undefined}
        />
    )
}
