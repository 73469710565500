export interface IDimensions {
    minX: number
    maxX: number
    minY: number
    maxY: number
    minXMargin: number
    maxXMargin: number
    minYMargin: number
    maxYMargin: number
    width: number
    height: number
    cx: number
    cy: number
}

export const getDimensions = (
    nodes: Array<any> = [],
    individuWitdh: number,
    margin: number
): IDimensions => {
    let minX: number = Number.MAX_VALUE
    let minY: number = Number.MAX_VALUE
    let maxX: number = Number.MIN_VALUE
    let maxY: number = Number.MIN_VALUE

    nodes.forEach((node) => {
        minX = Math.min(minX, node.x)
        minY = Math.min(minY, node.y)
        maxX = Math.max(maxX, node.x)
        maxY = Math.max(maxY, node.y)
    })

    return {
        minX,
        maxX,
        minY,
        maxY,
        minXMargin: minX - margin,
        maxXMargin: maxX + margin + individuWitdh,
        minYMargin: minY - margin,
        maxYMargin: maxY + margin + individuWitdh,
        width: maxX - minX || individuWitdh,
        height: maxY - minY || individuWitdh,
        cx: minX + (maxX - minX) / 2,
        cy: minY + (maxY - minY) / 2,
    }
}
