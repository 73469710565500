import { v4 as uuid } from "uuid"

// lire le cookie pour connaître les individus déjà visionnés
export const individusVisionnes = () =>
    (localStorage.getItem("individusVisionnes") || "")
        ?.split(",")
        .map((i) => parseInt(i, 10))

// enregistrer le cookie pour les individus déjà visionnés
const enregistrerIndividusVisionnes = (individus: number[]) => {
    localStorage.setItem("individusVisionnes", individus.join(","))
}

// ajouter un individu à la liste des individus déjà visionnés
export const ajouterIndividuVisionne = (individu: number) => {
    const individus = individusVisionnes() || []
    if (!individus.includes(individu)) {
        enregistrerIndividusVisionnes([...individus, individu])
    }
}

// supprimer un individu de la liste des individus déjà visionnés
export const supprimerIndividuVisionne = (individu: number) => {
    const individus = individusVisionnes() || []
    enregistrerIndividusVisionnes(individus.filter((i) => i !== individu))
}

// Supprimer tous les individus de la liste des individus déjà visionnés
export const supprimerTousIndividusVisionnes = () => {
    enregistrerIndividusVisionnes([])
}

// tester si un individu a déjà été visionné
export const estIndividuVisionne = (individu: number) =>
    individusVisionnes()?.includes(individu) || false

export const getClientId = () => {
    const clientId = localStorage.getItem("clientId")
    if (clientId) {
        return clientId
    }
    const newClientId = uuid()
    localStorage.setItem("clientId", newClientId)
    return newClientId
}
