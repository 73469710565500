import React, { useEffect, useRef } from "react"
import "../styles/map.scss"
import { Communaute } from "@julienbdx/nous-sommes-common"
import SvgMap from "../classes/SvgMap"
import { IForceInABoxDataNode } from "../utils/ToForceInABox"
import "wicg-inert"

interface MapProps {
    communautes: Communaute[]
    // eslint-disable-next-line no-unused-vars
    mouseOverIndividuEvent: (node: IForceInABoxDataNode) => void
    // eslint-disable-next-line no-unused-vars
    mouseOutIndividuEvent: (node: IForceInABoxDataNode) => void
    // eslint-disable-next-line no-unused-vars
    mouseClickIndividuEvent: (node: IForceInABoxDataNode | undefined) => void
    initialSelectedNode: IForceInABoxDataNode | undefined
    nodeSelected: IForceInABoxDataNode | undefined
}

function Map({
    communautes,
    mouseOverIndividuEvent,
    mouseOutIndividuEvent,
    mouseClickIndividuEvent,
    initialSelectedNode,
    nodeSelected,
}: MapProps) {
    const refMap = useRef<HTMLDivElement>(null)

    document.addEventListener(
        "map-set-focus",
        ((e: CustomEvent) => {
            if (e.detail) {
                refMap.current?.removeAttribute("inert")
            } else {
                refMap.current?.setAttribute("inert", "")
            }
            // eslint-disable-next-line no-undef
        }) as EventListener,
        false
    )
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ;(window as any).svgMap = new SvgMap(
            communautes,
            "#map-container",
            mouseOverIndividuEvent,
            mouseOutIndividuEvent,
            mouseClickIndividuEvent,
            initialSelectedNode
        )
        // @ts-ignore
        window.svgMap.charger()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [communautes])

    document.getElementById("map-container")?.blur()

    return (
        <div
            id="map-container"
            ref={refMap}
            className={`map ${
                nodeSelected && nodeSelected.individu ? "with-selection" : ""
            }`}
        />
    )
}

export default Map
