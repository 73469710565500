import * as d3 from "d3"
import { Communaute, Individu, Tag } from "@julienbdx/nous-sommes-common"
import forceInABox from "../utils/forceInABox"
import {
    IForceInABoxData,
    IForceInABoxDataNode,
    ToForceInABox,
} from "../utils/ToForceInABox"
import { getDimensions } from "../utils/Dimensions"
import { ajouterIndividuVisionne, individusVisionnes } from "../utils/Cookie"
import { isSafari, isTouchDevice } from "../utils/misc"

interface ILink {
    source: IForceInABoxDataNode
    target: IForceInABoxDataNode | undefined
}

const BETA = window.location.origin === "https://beta.nous-sommes.fr"

export default class SvgMap {
    private readonly communautes: Communaute[]

    private graphData: IForceInABoxData = { nodes: [] }

    private width: number = window.outerWidth

    private height: number = window.outerHeight

    private landscape: boolean = window.innerWidth > window.innerHeight

    private svg:
        | d3.Selection<SVGSVGElement, unknown, HTMLElement, any>
        | undefined

    private main:
        | d3.Selection<SVGGElement, unknown, HTMLElement, any>
        | undefined

    private conteneurId: string

    private simulation:
        | d3.Simulation<d3.SimulationNodeDatum, undefined>
        | undefined

    private zoom: d3.ZoomBehavior<Element, unknown> | undefined

    private nodes: any

    public individuWitdh = 70

    public margin = 0.05 * Math.min(this.width, this.height)

    public debug = false

    private readonly mouseOverIndividuEvent: (
        // eslint-disable-next-line no-unused-vars
        node: IForceInABoxDataNode
    ) => void

    // eslint-disable-next-line no-unused-vars
    private readonly mouseOutIndividuEvent: (node: IForceInABoxDataNode) => void

    private readonly mouseClickIndividuEvent: (
        // eslint-disable-next-line no-unused-vars
        node: IForceInABoxDataNode | undefined
    ) => void

    private selectedNode: IForceInABoxDataNode | undefined

    private initialSelectedNode: IForceInABoxDataNode | undefined

    private mouseEventsInited = false

    private isShaked = false

    constructor(
        communautes: Communaute[],
        conteneur: string,
        // eslint-disable-next-line no-unused-vars
        mouseOverIndividuEvent: (node: IForceInABoxDataNode) => void,
        // eslint-disable-next-line no-unused-vars
        mouseOutIndividuEvent: (node: IForceInABoxDataNode) => void,
        mouseClickIndividuEvent: (
            // eslint-disable-next-line no-unused-vars
            node: IForceInABoxDataNode | undefined
        ) => void,
        initialSelectedNode: IForceInABoxDataNode | undefined
    ) {
        this.communautes = communautes
        this.conteneurId = conteneur
        this.mouseOverIndividuEvent = mouseOverIndividuEvent
        this.mouseOutIndividuEvent = mouseOutIndividuEvent
        this.mouseClickIndividuEvent = mouseClickIndividuEvent
        this.initialSelectedNode = initialSelectedNode
    }

    charger() {
        d3.select(this.conteneurId).select("svg").remove()

        this.svg = d3
            .select(this.conteneurId)
            .append("svg")
            .attr("width", this.width)
            .attr("height", this.height)
            .attr("id", "map")
        this.main = this.svg.append("g").attr("id", "main")
        this.main.append("g").attr("id", "templates")
        this.main.append("g").attr("id", "links")
        this.main
            .append("g")
            .attr("id", "nodes")
            .attr("style", `transform: translateY(-${-20 + this.height / 2}px)`)
        this.graphData = ToForceInABox(this.communautes)

        this.addQuadrillage()
        this.initForces()
        this.initNodes()
        this.initListener()
        this.initVisionnes()

        const map = document.getElementById("map")
        if (map) {
            map.onclick = (event) => {
                // @ts-ignore
                if (event.target.tagName.toUpperCase() === "SVG") {
                    this.removeLinks()
                    this.mouseClickIndividuEvent(undefined)
                    event.stopPropagation()

                    document.dispatchEvent(new CustomEvent("map-unselect"))
                }
            }
        }
    }

    private initForces() {
        if (this.svg === undefined) return

        let inited = false
        const that = this

        // Zoom functions
        function zoom(event: any) {
            that.main?.attr("transform", event.transform)
            that.svg?.attr(
                "class",
                event.transform.k <= 0.6 ? "stroke-bold" : ""
            )
        }

        // add zoom capabilities
        this.zoom = d3.zoom().on("zoom", zoom)
        // @ts-ignore
        this.zoom(this.svg)

        if (this.simulation) {
            /*  this.simulation
          .force("charge", null)
          .force("x", null)
          .force("y", null)
          .force("collide", null)
          .force("group", null) */
            this.supprimerLiens()
        }

        // Regroupement des nodes au centre de la map
        this.simulation = d3
            .forceSimulation()
            .force("charge", d3.forceManyBody())
            .force("x", d3.forceX(that.width / 2).strength(0.4))
            .force("y", d3.forceY(that.height / 2).strength(0.4))
            .force(
                "collide",
                d3
                    .forceCollide(this.individuWitdh)
                    .radius(this.individuWitdh)
                    .strength(0.375)
            )
            .alphaDecay(0.06251) // Sensibilité au déclenchement de la fin de la simulation
            .alpha(0.9)
        //  .alphaTarget(0.0025)

        // Force du regroupement en communauté
        const groupingForce = (forceInABox as any)()
            .strength(0.31) // Strength to foci
            .template("force") // Either treemap or force
            .groupBy("group") // Node attribute to group
            //     .links(this.graph.links) // The graph links. Must be called after setting the grouping attribute
            .enableGrouping(true)
            .forceNodeSize(this.individuWitdh * 1.25) // Used to compute the size of the template nodes, think of it as
            // the radius the node
            // uses,
            // including its padding
            // .forceCharge(1000) // Separation between nodes on the force template
            .size([this.width, this.height]) // Size of the chart

        this.simulation
            .nodes(this.graphData.nodes as d3.SimulationNodeDatum[])
            //  .force("collide", d3.forceCollide(50).radius(50).strength(0.19))
            .on("end", () => {
                if (!inited && that.simulation) {
                    // Empêcher le chevauchement des nodes
                    that.simulation
                        .force("charge", null)
                        .stop()
                        .force(
                            "x",
                            d3
                                .forceX(that.width / 2)
                                .strength(this.landscape ? 0 : 0.4)
                        )
                        .force(
                            "y",
                            d3
                                .forceY(that.height / 2)
                                .strength(this.landscape ? 0.4 : 0)
                        )
                        .force(
                            "collide",
                            d3
                                .forceCollide(this.individuWitdh * 0.9)
                                .radius(this.individuWitdh * 0.9)
                                .strength(0.4375)
                        )

                    that.simulation
                        .stop()
                        .force("group", groupingForce)
                        .alphaTarget(0.07805)
                        .restart()

                    //     this.simulation.force("group").drawTemplate(this.svg.select("#templates"))

                    inited = true

                    setTimeout(() => {
                        if (
                            that.initialSelectedNode &&
                            that.initialSelectedNode.communaute &&
                            that.initialSelectedNode.individu &&
                            that.initialSelectedNode.individu.visible
                        ) {
                            // Passage de l'icone a l'état lu
                            that.svg
                                ?.select(
                                    `#ind_${that.initialSelectedNode.individu?.id}`
                                )
                                .attr(
                                    "fill",
                                    that.initialSelectedNode.communaute?.couleur
                                        .hex || ""
                                )

                            document.dispatchEvent(
                                new CustomEvent("map-select-individu", {
                                    detail: {
                                        individu:
                                            that.initialSelectedNode.individu,
                                        communaute:
                                            that.initialSelectedNode.communaute,
                                    },
                                })
                            )

                            document.dispatchEvent(
                                new CustomEvent("map-individu-visionne", {
                                    detail: {
                                        individu:
                                            that.initialSelectedNode.individu,
                                        communaute:
                                            that.initialSelectedNode.communaute,
                                    },
                                })
                            )
                        } else if (
                            that.initialSelectedNode &&
                            that.initialSelectedNode.communaute
                        ) {
                            document.dispatchEvent(
                                new CustomEvent("map-select-communaute", {
                                    detail: that.initialSelectedNode.communaute,
                                })
                            )
                        } else {
                            that.zoomOn(that.graphData.nodes, 0.9, () => {
                                //      that.identifyCommunities()
                            })
                        }

                        if (!this.mouseEventsInited) {
                            this.initMouseEvents()
                        }

                        d3.select("body").classed("inited", true)
                    }, 3000)
                }
            })

        this.simulation.on("tick", () => {
            this.nodes.attr("transform", (d: any) => `translate(${d.x} ${d.y})`)
        })
    }

    private identifyCommunities() {
        if (!document.getElementById("front"))
            this.main?.append("g").attr("id", "front")

        this.communautes.forEach((communaute) => {
            if (this.graphData) {
                const communauteNodes = this.graphData.nodes.filter(
                    (elem) => elem.individu && elem.group === communaute.id
                )

                // Détermination du centre de la communaute
                const minX = Math.min.apply(
                    null,
                    communauteNodes.map((node) => node.x as number)
                )
                // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
                const maxX =
                    Math.max.apply(
                        null,
                        communauteNodes.map((node) => node.x as number)
                    ) + this.individuWitdh

                const minY =
                    Math.min.apply(
                        null,
                        communauteNodes.map((node) => node.y as number)
                    ) -
                    this.individuWitdh / 2
                // eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
                const maxY =
                    Math.max.apply(
                        null,
                        communauteNodes.map((node) => node.y as number)
                    ) + this.individuWitdh

                /* this.svg
                    ?.select("#front")
                    .append("rect")
                    .attr("x", minX)
                    .attr("y", minY)
                    .attr("width", maxX - minX)
                    .attr("height", maxY - minY)
                    .attr("rx", "10")
                    .attr("class", "communaute-zone ")
                    .attr("fill", `${communaute.couleur.hex}44`)
                    .attr("stroke", `${communaute.couleur.hex}`)
                    .attr("stroke-width", "3") */

                this.svg
                    ?.select("#front")
                    .append("text")
                    .attr("x", minX + 15) // (maxX + minX) / 2)
                    .attr("y", minY - 15) // (minY + maxY) / 2)
                    .attr("text-anchor", "start")
                    .attr("fill", "#000000")
                    .attr("class", "communaute-zone-text")
                    .html(communaute.libelle)
            }
        })

        window.setTimeout(() => {
            this.svg
                ?.selectAll("rect.communaute-zone")
                .transition()
                .delay(500)
                .duration(1000)
                .style("opacity", 0)
                .end()
                .then(() => {
                    this.svg
                        ?.select("#front")
                        .selectAll("rect.communaute-zone")
                        .remove()
                })

            this.svg
                ?.selectAll("text.communaute-zone-text")
                .transition()
                .delay(500)
                .duration(1000)
                .style("opacity", 0)
                .end()
                .then(() => {
                    this.svg
                        ?.select("#front")
                        .selectAll("text.communaute-zone-text")
                        .remove()
                })
        }, 20000)
    }

    private initNodes() {
        // @ts-ignore
        this.nodes = this.main
            ?.select("#nodes")
            ?.selectAll(".node")
            .data(this.graphData.nodes)
            .enter()
            .append("g")
            .attr("class", (d) => {
                if (!d.individu?.visible) {
                    return `node communaute_${d.communaute?.id} soon`
                }

                return `node communaute_${d.communaute?.id} ${
                    d.visionne ? "visionne" : ""
                } couleur_${d.communaute?.couleur.id}`
            })
            .attr("id", (d) => `ind_${d.individu?.id}`)
            .attr("fill", (d) => {
                if (!d.individu?.visible) {
                    return "#FFF"
                }
                return d.visionne
                    ? (d.communaute?.couleur.hex as string)
                    : "#ffffff"
            })
            .attr("tabindex", "0")
            .attr(
                "aria-label",
                (d) =>
                    `${d.individu?.prenom} de la communauté ${
                        d.communaute?.libelle
                    }. Tags associés : ${d.individu?.tags
                        .map((t) => t.libelle)
                        .join(", ")}`
            )
            .html((d): string =>
                d.individu?.variation
                    ? (d.communaute?.formeVariation.svg as string)
                    : (d.communaute?.forme.svg as string)
            )

        this.nodes
            .style("opacity", 0)
            .transition()
            .delay(500)
            .duration(1000)
            .style("opacity", 1)
    }

    private initMouseEvents() {
        window.scrollTo(0, 0)
        this.nodes
            .on("click", (ev: any, node: IForceInABoxDataNode) => {
                if (!node.individu?.visible && !BETA) return
                this.nodeClicked(node)
            })
            .on("mouseleave", (ev: any, node: IForceInABoxDataNode) => {
                this.onMouseOut(node)
            })
            .on("mouseover", (ev: any, node: IForceInABoxDataNode) => {
                this.onMouseOver(node)
            })
            .on("focus", (ev: any, node: IForceInABoxDataNode) => {
                this.onMouseOver(node)
            })
            .on("focusout", (ev: any, node: IForceInABoxDataNode) => {
                this.onMouseOut(node)
            })
            .on("keyup", (ev: any, node: IForceInABoxDataNode) => {
                if (
                    ev.code === "Enter" ||
                    ev.code === "Space" ||
                    ev.code === "NumpadEnter"
                )
                    this.nodeClicked(node)
            })
    }

    private supprimerLiens() {
        this.svg?.classed("with-selection", false)
        this.svg?.selectAll("#links .link").remove()
    }

    private zoomOn(
        nodes: Array<any> = [],
        scaleFactor = 0.9,
        callback?: () => void
    ): void {
        if (nodes.length === 0) {
            // eslint-disable-next-line no-param-reassign
            nodes = this.graphData.nodes
        }

        const dim = getDimensions(nodes, this.individuWitdh, this.margin)
        if (this.debug) {
            this.svg?.select("#templates").selectAll(".area").remove()
            this.svg
                ?.select("#templates")
                .append("rect")
                .attr("x", dim.minX)
                .attr("y", dim.minY)
                .attr("width", dim.width || this.individuWitdh)
                .attr("height", dim.height || this.individuWitdh)
                .attr("stroke", "red")
                .attr("fill", "transparent")
                .classed("area", true)
        }

        const scale = Math.min(
            4,
            Math.min(this.width / dim.width, this.height / dim.height) *
                scaleFactor
        )

        this.svg
            ?.transition()
            .duration(2500)
            .call(
                this.zoom?.transform as any,
                d3.zoomIdentity
                    .translate(this.width / 2, this.height / 2)
                    .scale(scale)
                    .translate(
                        -dim.cx - this.individuWitdh / 2,
                        -dim.cy - this.individuWitdh / 2
                    )
            )
            .on("end", () => {
                if (callback) {
                    callback()
                }
            })
    }

    private onMouseOver(node: IForceInABoxDataNode) {
        this.mouseOverIndividuEvent(node)

        if (!node.visionne)
            if (node.individu?.variation) {
                this.svg
                    ?.select(`#ind_${node.individu?.id}`)
                    .attr(
                        "fill",
                        `url(#pattern_variant_c${node.communaute?.id})`
                    )
            } else {
                this.svg
                    ?.select(`#ind_${node.individu?.id}`)
                    .attr("fill", node.communaute?.couleur.hex || "#000")
            }

        /*  if (!node.visionne)
            this.svg
                ?.select(`#ind_${node.individu?.id}`)
                .attr("fill", node.communaute?.couleur.hex || "") */

        if (isTouchDevice() && isSafari()) {
            this.nodeClicked(node)
        }
    }

    private nodeClicked(node: IForceInABoxDataNode) {
        if (!node.individu) return
        //  this.removeLinks()
        this.selectedNode = node

        if (!node.visionne) {
            // eslint-disable-next-line no-param-reassign
            node.visionne = true
        }

        this.mouseClickIndividuEvent(node)
    }

    private onMouseOut(node: IForceInABoxDataNode) {
        this.mouseOutIndividuEvent(node)
        if (!node.individu?.visible) {
            this.svg
                ?.select(`#ind_${node.individu?.id}`)
                .attr("fill", "#FFFFFF")
        } else if (!node.visionne)
            this.svg
                ?.select(`#ind_${node.individu?.id}`)
                .attr("fill", "#FFFFFF")
    }

    public drawLinks(origin: Individu | undefined, tag: Tag) {
        // Réinitialisation
        this.removeLinks()
        this.svg
            // ?.classed("node-selected", true)
            ?.selectAll(".node")
            .attr("class", (d: any) => {
                if (!d.individu?.visible) {
                    return "soon"
                }

                return `node communaute_${d.communaute.id} ${
                    d.visionne ? "visionne" : ""
                }`
            })

        this.simulation?.stop()

        const links = this.getLinks(origin, tag)

        if (links.length === 0) {
            window.setTimeout(() => {
                const selected = this.graphData.nodes.filter(
                    (elem) =>
                        elem.individu &&
                        elem.individu.tags.filter((t) => t.id === tag.id)
                            .length > 0
                )

                selected.forEach((n) =>
                    this.svg
                        ?.select(`g#ind_${n.individu?.id}`)
                        .classed("selected", true)
                )

                document
                    .getElementById("map-container")
                    ?.classList.add("with-selection")

                this.zoomOn(selected, 0.65)
            }, 500)
        } else {
            links.forEach(
                (link: { source: any; target: any }, index: number) => {
                    setTimeout(() => {
                        this.svg
                            ?.select(`g#ind_${link.source.individu.id}`)
                            .classed("selected", true)
                            .classed("linked", true)

                        this.svg
                            ?.select("#links")
                            .append("line")
                            .attr("x1", link.source.x + this.individuWitdh / 2)
                            .attr("y1", link.source.y + this.individuWitdh / 2)
                            .attr("x2", link.target.x + this.individuWitdh / 2)
                            .attr("y2", link.target.y + this.individuWitdh / 2)
                            .classed("link", true)
                            .style("opacity", 0)
                            .style("stroke-width", 2)
                            .style("opacity", 1)

                        this.svg
                            ?.select(`g#ind_${link.target.individu.id}`)
                            .classed("selected", true)
                            .classed("linked", true)

                        if (index === links.length - 1) {
                            document
                                .getElementById("map-container")
                                ?.classList.add("with-selection")
                        }
                    }, index * 200)
                }
            )

            this.zoomOn(
                [
                    ...links.map((item) => item.source),
                    ...links.map((item) => item.target),
                ],
                0.65
            )
        }
    }

    private getLinks(origin: Individu | undefined, tag: Tag): ILink[] {
        const links = []

        if (this.graphData) {
            let selectedNodes = this.graphData.nodes.filter(
                (elem) =>
                    elem.individu &&
                    elem.individu.tags.filter((t) => t.id === tag.id).length > 0
            )
            let prev =
                selectedNodes.filter(
                    (item) => item.individu && item.individu.id === origin?.id
                )[0] || selectedNodes.pop()

            // eslint-disable-next-line no-unreachable
            while (selectedNodes.length > 0) {
                let closest
                let distance: number | undefined
                let index = null

                // eslint-disable-next-line no-loop-func
                selectedNodes.forEach((candidat, indexCandidat) => {
                    if (prev === undefined || candidat === undefined) {
                        return
                    }

                    const distanceCandidat = Math.sqrt(
                        // @ts-ignore
                        // eslint-disable-next-line no-restricted-properties
                        Math.pow(prev.x - candidat.x, 2) +
                            // @ts-ignore
                            // eslint-disable-next-line no-restricted-properties
                            Math.pow(prev.y - candidat.y, 2)
                    )
                    if (distance === undefined || distanceCandidat < distance) {
                        closest = candidat
                        distance = distanceCandidat
                        index = indexCandidat
                    }
                })

                links.push({ source: prev, target: closest })
                selectedNodes = [
                    ...selectedNodes.slice(0, index || 0),
                    ...selectedNodes.slice((index || 0) + 1),
                ]
                // @ts-ignore
                prev = closest
            }
        }

        return links
    }

    private removeLinks() {
        const map = document.getElementById("map")
        map?.classList.remove("node-selected")
        this.svg?.selectAll("#links .link").remove()
        document
            .getElementById("map-container")
            ?.classList.remove("with-selection")

        this.svg?.selectAll(`g.node`).classed("linked", false)
    }

    private addQuadrillage() {
        const step = this.height / 6

        for (let i = -20.5; i < 40; i += 1) {
            this.svg
                ?.select("#templates")
                .append("line")
                .attr("x1", i * step)
                .attr("y1", -100 * this.width)
                .attr("x2", i * step)
                .attr("y2", 100 * this.width)
                .classed("quadrillage", true)

            this.svg
                ?.select("#templates")
                .append("line")
                .attr("x1", -100 * this.height)
                .attr("y1", i * step)
                .attr("x2", 100 * this.height)
                .attr("y2", i * step)
                .classed("quadrillage", true)
        }
    }

    private resizeSvg() {
        window.scrollTo(0, 0)
        window.setTimeout(() => {
            this.width = window.outerWidth
            this.height = window.outerHeight
            document
                .getElementById("map")
                ?.setAttribute("width", window.outerWidth.toString())
            document
                .getElementById("map")
                ?.setAttribute("height", window.outerHeight.toString())
        }, 500)
    }

    private initListener() {
        const that = this

        window.addEventListener("resize", () => {
            that.resizeSvg()
        })

        document.addEventListener(
            "resize-svg",
            (() => {
                that.resizeSvg()
                // eslint-disable-next-line no-undef
            }) as EventListener,
            false
        )

        document.addEventListener(
            "set-shaked",
            ((e: CustomEvent) => {
                that.isShaked = e.detail
                // eslint-disable-next-line no-undef
            }) as EventListener,
            false
        )

        document.addEventListener(
            "map-unzoom",
            (() => {
                that.removeLinks()
                that.initForces()
                // eslint-disable-next-line no-undef
            }) as EventListener,
            false
        )

        document.addEventListener(
            "map-unselect",
            (() => {
                this.removeLinks()
                this.mouseClickIndividuEvent(undefined)
                // event.stopPropagation()
                // eslint-disable-next-line no-undef
            }) as EventListener,
            false
        )

        document.addEventListener(
            "map-select-communaute",
            ((e: CustomEvent) => {
                that.removeLinks()

                if (that.isShaked) {
                    document.dispatchEvent(
                        new CustomEvent("map-groupby-communaute", {
                            detail: false,
                        })
                    )
                }

                window.setTimeout(
                    () => {
                        that.zoomOn(
                            that.graphData.nodes.filter(
                                (node) => node.communaute?.id === e.detail.id
                            ),
                            0.55
                        )
                    },
                    that.isShaked ? 2000 : 0
                )
                // eslint-disable-next-line no-undef
            }) as EventListener,
            false
        )

        document.addEventListener(
            "map-select-individu",
            ((e: CustomEvent) => {
                that.removeLinks()
                // eslint-disable-next-line prefer-destructuring
                that.selectedNode = that.graphData.nodes.filter(
                    (node) => node.individu?.id === e.detail.individu?.id
                )[0]
                that.zoomOn(
                    that.graphData.nodes.filter(
                        (node) => node.individu?.id === e.detail.individu?.id
                    )
                )
                // eslint-disable-next-line no-undef
            }) as EventListener,
            false
        )

        document.addEventListener(
            "map-select-tag",
            ((e: CustomEvent) => {
                const tagSelected: Tag = e.detail
                that.drawLinks(that.selectedNode?.individu, tagSelected)
                // eslint-disable-next-line no-undef
            }) as EventListener,
            false
        )

        document.addEventListener(
            "map-individu-visionne",
            ((e: CustomEvent) => {
                if (!e.detail.individu || !e.detail.individu.visible) {
                    return
                }

                if (e.detail.individu.variation) {
                    that.svg
                        ?.select(`#ind_${e.detail.individu?.id}`)
                        .attr(
                            "fill",
                            `url(#pattern_variant_c${e.detail.communaute?.id})`
                        )
                } else {
                    that.svg
                        ?.select(`#ind_${e.detail.individu?.id}`)
                        .attr(
                            "fill",
                            e.detail.communaute?.couleur.hex || "#000"
                        )
                }

                that.graphData.nodes
                    .filter(
                        (node) => node.individu?.id === e.detail.individu.id
                    )
                    .forEach((n) => {
                        // eslint-disable-next-line no-param-reassign
                        n.visionne = true
                    })

                ajouterIndividuVisionne(
                    parseInt(e.detail.individu?.id as string, 10)
                )
                // eslint-disable-next-line no-undef
            }) as EventListener,
            false
        )

        document.addEventListener(
            "map-shake",
            (() => {
                that.shakeShakeShake()
                // eslint-disable-next-line no-undef
            }) as EventListener,
            false
        )

        document.addEventListener(
            "map-groupby-communaute",
            ((e: CustomEvent) => {
                that.supprimerLiens()
                that.removeLinks()

                that.simulation
                    ?.force("x", null)
                    .force("y", null)
                    .force("collide", null)
                    .force("group", null)
                    .stop()

                // Empêcher le chevauchement des nodes
                that.simulation
                    ?.force("charge", null)
                    .stop()
                    .force(
                        "x",
                        d3
                            .forceX(that.width / 2)
                            .strength(this.landscape ? 0 : 0.4)
                    )
                    .force(
                        "y",
                        d3
                            .forceY(that.height / 2)
                            .strength(this.landscape ? 0.4 : 0)
                    )
                    .force(
                        "collide",
                        d3
                            .forceCollide(this.individuWitdh * 0.9)
                            .radius(this.individuWitdh * 0.9)
                            .strength(0.4375)
                    )

                // Force du regroupement en communauté
                const groupingForce = (forceInABox as any)()
                    .strength(0.31) // Strength to foci
                    .template("force") // Either treemap or force
                    .groupBy("group") // Node attribute to group
                    //     .links(this.graph.links) // The graph links. Must be called after setting the grouping attribute
                    .enableGrouping(true)
                    .forceNodeSize(that.individuWitdh * 1.25) // Used to compute the size of the template nodes, think of it as
                    // the radius the node
                    // uses,
                    // including its padding
                    // .forceCharge(1000) // Separation between nodes on the force template
                    .size([that.width, that.height]) // Size of the chart

                that.simulation
                    ?.stop()
                    .force("group", groupingForce)
                    .alphaTarget(0.07805)
                    .restart()

                //     this.simulation.force("group").drawTemplate(this.svg.select("#templates"))

                if (e.detail !== false) {
                    setTimeout(() => {
                        if (
                            that.initialSelectedNode &&
                            that.initialSelectedNode.communaute &&
                            that.initialSelectedNode.individu
                        ) {
                            // Passage de l'icone a l'état lu
                            that.svg
                                ?.select(
                                    `#ind_${that.initialSelectedNode.individu?.id}`
                                )
                                .attr(
                                    "fill",
                                    that.initialSelectedNode.communaute?.couleur
                                        .hex || ""
                                )

                            document.dispatchEvent(
                                new CustomEvent("map-select-individu", {
                                    detail: {
                                        individu:
                                            that.initialSelectedNode.individu,
                                        communaute:
                                            that.initialSelectedNode.communaute,
                                    },
                                })
                            )

                            document.dispatchEvent(
                                new CustomEvent("map-individu-visionne", {
                                    detail: {
                                        individu:
                                            that.initialSelectedNode.individu,
                                        communaute:
                                            that.initialSelectedNode.communaute,
                                    },
                                })
                            )
                        } else if (
                            that.initialSelectedNode &&
                            that.initialSelectedNode.communaute
                        ) {
                            document.dispatchEvent(
                                new CustomEvent("map-select-communaute", {
                                    detail: that.initialSelectedNode.communaute,
                                })
                            )
                        } else {
                            that.zoomOn(that.graphData.nodes, 0.9, () => {
                                //   that.identifyCommunities()
                            })
                        }

                        if (!this.mouseEventsInited) {
                            this.initMouseEvents()
                        }

                        d3.select("body").classed("inited", true)
                    }, 3000)
                }
                // eslint-disable-next-line no-undef
            }) as EventListener,
            false
        )
    }

    public shakeShakeShake() {
        document.dispatchEvent(
            new CustomEvent("video-affichage", { detail: false })
        )
        document.dispatchEvent(new CustomEvent("map-unselect"))

        this.supprimerLiens()

        this.graphData.nodes.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            item.groupShaker =
                item.individu?.prenom?.charAt(
                    Math.floor(Math.random() * item.individu.prenom.length)
                ) || ""
        })

        const groupingForce = (forceInABox as any)()
            .strength(0.043) // Strength to foci
            .groupBy("groupShaker") // Node attribute to group
            .enableGrouping(true)
            .forceNodeSize(1) // Used to compute the size of the template nodes, think of it as the radius the node
            // uses,
            // including its padding
            .linkStrengthIntraCluster(0)
            .linkStrengthInterCluster(0)
            .forceCharge(0) // Separation between nodes on the force template
            .forceLinkDistance(0) // Separation between nodes on the force template
            .forceLinkStrength(0.975) // Separation between nodes on the force template
            .size([this.width / 1.5, this.height / 1.5]) // Size of the chart

        this.simulation
            ?.force(
                "x",
                d3
                    .forceX(this.width / 2)
                    .strength(this.landscape ? 0.1905 : 0.2)
            )
            .force(
                "y",
                d3
                    .forceY(this.height / 2)
                    .strength(this.landscape ? 0.2 : 0.1905)
            )
            .force("collide", d3.forceCollide(10).radius(10).strength(0.15))
            .force("group", null)
            .restart()

        setTimeout(() => {
            this.zoomOn(this.graphData.nodes, 0.75)
            this.simulation?.stop().force("group", groupingForce).restart()
        }, 500)

        setTimeout(() => {
            groupingForce.strength(0.034)
            this.simulation?.force("group", groupingForce)
        }, 2000)

        setTimeout(() => {
            this.simulation
                ?.stop()
                .force(
                    "x",
                    d3
                        .forceX(this.width / 2)
                        .strength(this.landscape ? 0.1 : 0.15)
                )
                .force(
                    "y",
                    d3
                        .forceY(this.height / 2)
                        .strength(this.landscape ? 0.15 : 0.1)
                )
                .force(
                    "collide",
                    d3
                        .forceCollide(70)
                        .radius(this.individuWitdh * 1.33)
                        .strength(0.1)
                )
                .force("group", null)
                .restart()
        }, 3000)

        setTimeout(() => {
            this.zoomOn(this.graphData.nodes, 0.8)
        }, 5500)
    }

    private initVisionnes() {
        individusVisionnes()?.forEach((i) => {
            const node = this.graphData.nodes.find(
                (n: IForceInABoxDataNode) =>
                    parseInt(n.individu?.id as string, 10) === i
            )
            if (node)
                document.dispatchEvent(
                    new CustomEvent("map-individu-visionne", {
                        detail: {
                            individu: node.individu,
                            communaute: node.communaute,
                        },
                    })
                )
        })
    }
}
