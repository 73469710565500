import React, { Dispatch } from "react"
import { Communaute } from "@julienbdx/nous-sommes-common"
import Modale from "./Modale"
import "../styles/modalecommunaute.scss"

interface ModaleCommunauteProps {
    open: boolean
    setOpen: Dispatch<boolean>
    communaute: Communaute | undefined
}

function ModaleCommunaute({
    open,
    setOpen,
    communaute,
}: ModaleCommunauteProps) {
    if (!communaute) return null
    return (
        <Modale
            open={open}
            setOpen={setOpen}
            className="modale-communaute"
            content={
                <>
                    <div className="content-modale-communaute">
                        <div
                            className="communaute-forme text-center"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: communaute.formeCouleur(true),
                            }}
                        />
                        <h1 className="text-center">{communaute.libelle}</h1>
                        {communaute.presentation && (
                            <>
                                <h2>Présentation</h2>
                                <div // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={{
                                        __html: communaute.presentation,
                                    }}
                                />
                            </>
                        )}
                        {communaute.partenaires && (
                            <>
                                <h2>Partenaires</h2>
                                <p className="partenaires">
                                    <div
                                        // eslint-disable-next-line react/no-danger
                                        dangerouslySetInnerHTML={{
                                            __html: communaute.partenaires,
                                        }}
                                    />
                                </p>
                            </>
                        )}
                        {communaute.lien && (
                            <>
                                <h2>Plus d&apos;informations</h2>
                                <p>
                                    <a
                                        href={communaute.lien}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {communaute.lien}
                                    </a>
                                </p>
                            </>
                        )}
                    </div>
                </>
            }
        />
    )
}

export default ModaleCommunaute
