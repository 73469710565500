import React, { useEffect, useState } from "react"
import "../styles/noussommes.scss"
import {
    Communaute,
    communautesFromHydra,
    ENDPOINT,
    fetchApi,
    Tag,
    tagsFromHydra,
} from "@julienbdx/nous-sommes-common"
import { IHydraResponse } from "@julienbdx/nous-sommes-common/lib/classes/hydra"
import { useNavigate } from "react-router-dom"
import Loading from "./Loading"
import Map from "./Map"
import NavBar from "./NavBar"
import { IForceInABoxDataNode } from "../utils/ToForceInABox"
import VideoPlayer from "./VideoPlayer"
import { ajouterIndividuVisionne, getClientId } from "../utils/Cookie"
import { removeAllClass } from "../utils/misc"

export const DEV =
    window.location.origin === "http://localhost:3000" ||
    window.location.origin === "http://localhost:8080"

interface NousSommesProps {
    communauteSlug?: string
    individuSlug?: string
}

NousSommes.defaultProps = {
    communauteSlug: "",
    individuSlug: "",
}

export function getEndpoint() {
    return DEV ? "http://127.0.0.1:8000/api" : ENDPOINT
}

function NousSommes({
    communauteSlug = "",
    individuSlug = "",
}: NousSommesProps) {
    const [communautes, setCommunautes] = useState<Communaute[]>([])
    const [tags, setTags] = useState<Tag[]>([])
    const [nodeSelected, setNodeSelected] = useState<
        IForceInABoxDataNode | undefined
    >()
    const [prevNodeSelected, setPrevNodeSelected] = useState<
        IForceInABoxDataNode | undefined
    >()

    const [nodeHover, setNodeHover] = useState<
        IForceInABoxDataNode | undefined
    >()

    const [tagSelected, setTagSelected] = useState<Tag | undefined>()

    const [etat, setEtat] = useState("init")

    const [loadingMessage, setLoadindMessage] = useState(
        "Chargement en cours..."
    )

    const [afficherVideo, setAfficherVideo] = useState(false)

    const navigate = useNavigate()

    const IndividuOverEvent = (node: IForceInABoxDataNode) => {
        setTagSelected(undefined)
        setNodeHover(node)
        setAfficherVideo(false)
    }

    const IndividuOutEvent = () => {}

    const IndividuClickEvent = (node: IForceInABoxDataNode | undefined) => {
        if (node) {
            ajouterIndividuVisionne(parseInt(node?.individu?.id as string, 10))
            navigate(`/${node?.communaute?.slug}/${node?.individu?.slug}`)
        } else {
            navigate(`/`)
        }

        setTagSelected(undefined)
        setNodeSelected(node)
        setTimeout(() => {
            setAfficherVideo(true)
        }, 1000)

        document.dispatchEvent(
            new CustomEvent("navbar-set-mode-recherche", { detail: false })
        )

        document.dispatchEvent(
            new CustomEvent("map-individu-visionne", {
                detail: {
                    individu: node?.individu,
                    communaute: node?.communaute,
                },
            })
        )
    }

    // Désélectionner individu et tag
    document.addEventListener(
        "map-unselect",
        (() => {
            setTagSelected(undefined)
            setNodeSelected(undefined)
            // eslint-disable-next-line no-undef
        }) as EventListener,
        false
    )

    // Lorsqu'un tag est sélectionné
    document.addEventListener(
        "map-select-tag",
        ((e: CustomEvent) => {
            setTagSelected(e.detail)
            setAfficherVideo(false)
            setNodeHover(undefined)
            setNodeSelected(undefined)
            // eslint-disable-next-line no-undef
        }) as EventListener,
        false
    )

    // Lorsqu'une communauté est sélectionnée
    document.addEventListener(
        "map-select-communaute",
        ((e: CustomEvent) => {
            setNodeSelected({
                group: e.detail.id,
                individu: undefined,
                communaute: e.detail,
                visionne: false,
                groupShaker: "",
            })
            setAfficherVideo(false)
            // eslint-disable-next-line no-undef
        }) as EventListener,
        false
    )

    // Lorsqu'un individu est sélectionné
    document.addEventListener(
        "map-select-individu",
        ((e: CustomEvent) => {
            setNodeSelected({
                group: e.detail?.communaute?.id || 0,
                individu: e.detail?.individu,
                communaute: e.detail?.communaute,
                visionne: true,
                groupShaker: "",
            })
            setTimeout(() => {
                setAfficherVideo(true)
            }, 2000)
            // eslint-disable-next-line no-undef
        }) as EventListener,
        false
    )

    // Toggle de l'affichage de la vidéo
    document.addEventListener(
        "video-affichage",
        ((e: CustomEvent) => {
            setAfficherVideo(e.detail)
            // eslint-disable-next-line no-undef
        }) as EventListener,
        false
    )

    useEffect(() => {
        window.setTimeout(() => {
            window.scrollTo(0, 0)
        }, 2000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Chargements de communautés (avec individus)
    useEffect(() => {
        fetchApi(getEndpoint(), "/api/communautes?full=1")
            .then((data: IHydraResponse) => {
                setCommunautes(communautesFromHydra(data))
                setEtat("loading")
            })
            .catch((error: Error) => {
                setLoadindMessage("Erreur lors du chargement")
                // eslint-disable-next-line no-console
                console.error(error)
                setEtat("erreur")
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Chargement des tags
    useEffect(() => {
        fetchApi(getEndpoint(), "/api/tags?itemsPerPage=1000")
            .then((data: IHydraResponse) => {
                setTags(tagsFromHydra(data))
            })
            .catch((error: Error) => {
                // eslint-disable-next-line no-console
                console.error(error)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        removeAllClass("selected")
        if (nodeSelected && nodeSelected.individu && nodeSelected.communaute) {
            // mise à jour du titre de la page
            document.title = `Nous sommes - ${nodeSelected.individu.prenom} de "${nodeSelected.communaute.libelle}"`

            document
                .getElementById("map-container")
                ?.classList.add("with-selection")
            document
                .getElementById(`ind_${nodeSelected?.individu?.id}`)
                ?.classList.add("selected")
        } else {
            document.title = "Nous sommes"
        }
    }, [nodeSelected])

    // Focus sur l'individu ou la communauté passé en paramètre
    useEffect(() => {
        let communaute
        if (communauteSlug) {
            communaute =
                communautes.filter((item) => item.slug === communauteSlug)[0] ||
                undefined
        }

        let individu
        if (individuSlug && communaute) {
            individu =
                communaute.individus.filter(
                    (item) => item.slug === individuSlug
                )[0] || undefined
            if (individu) {
                ajouterIndividuVisionne(parseInt(individu?.id as string, 10))
            }
        }
        setNodeHover({
            group: communaute?.id || 0,
            individu,
            communaute,
            visionne: true,
            groupShaker: "",
        })
        setNodeSelected({
            group: communaute?.id || 0,
            individu,
            communaute,
            visionne: true,
            groupShaker: "",
        })

        if (communaute && !individu) {
            navigate(`/${communaute?.slug}`)
        }

        if (etat === "loading") {
            setEtat("loaded")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [etat, communauteSlug, communautes, individuSlug])

    useEffect(() => {
        if (
            !DEV &&
            nodeSelected &&
            nodeSelected.communaute &&
            (prevNodeSelected === undefined ||
                prevNodeSelected.individu?.id !== nodeSelected.individu?.id)
        ) {
            setPrevNodeSelected(nodeSelected)
            fetchApi(getEndpoint(), "/api/consultations", {
                method: "POST",
                body: JSON.stringify({
                    individu: nodeSelected?.individu
                        ? `/api/individus/${nodeSelected?.individu?.id}`
                        : undefined,
                    communaute: nodeSelected?.communaute
                        ? `/api/communautes/${nodeSelected?.communaute?.id}`
                        : undefined,
                    source: window.location.origin,
                    ident: getClientId(),
                    width: window.innerWidth,
                    height: window.innerHeight,
                    client: JSON.stringify({
                        navigator: {
                            userAgent: navigator.userAgent,
                            platform: navigator.platform,
                            language: navigator.language,
                            vendor: navigator.vendor,
                        },
                    }),
                }),
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeSelected])

    return (
        <>
            {etat === "init" || etat === "loading" || etat === "erreur" ? (
                <Loading message={loadingMessage} />
            ) : (
                <div id="nous-sommes">
                    <Map
                        communautes={communautes}
                        mouseOverIndividuEvent={IndividuOverEvent}
                        mouseOutIndividuEvent={IndividuOutEvent}
                        mouseClickIndividuEvent={IndividuClickEvent}
                        initialSelectedNode={nodeSelected}
                        nodeSelected={nodeSelected}
                    />
                    <NavBar
                        nodeSelected={nodeSelected}
                        nodeHover={nodeHover}
                        tagSelected={tagSelected}
                        communautes={communautes}
                        tags={tags}
                    />
                    {afficherVideo && nodeSelected && nodeSelected.individu ? (
                        <VideoPlayer
                            individu={nodeSelected?.individu}
                            communaute={nodeSelected?.communaute as Communaute}
                        />
                    ) : null}
                </div>
            )}
        </>
    )
}

export default NousSommes
